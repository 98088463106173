// @flow
import React, { useRef, useState, lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { useAction } from '@reatom/react'
import axios from 'axios'
import { Loader, Spinner } from '../components/screens/Loader'
import { SubscriberLoaded } from '../atoms/Subscriber'
import { goToStep } from '../atoms/Steps'
import { FingerprintLoaded } from '../atoms/AppConfig'
import { useHistory } from 'react-router-dom'

const LazyPopup = lazy(() => import('reactjs-popup'))

export const useSignIn = () => {
  const [popUpOpen, setPopUpState] = useState(false)
  const [showSpinner, setSpinnerState] = useState(false)
  const [otpSent, setOTPSent] = useState(false)
  const [showOTPPopUp, setOTPPopUpState] = useState(false)
  const [signInError, setSignInError] = useState(null)
  const [otpError, setOTPError] = useState(null)
  const [signedIn, setSignedInState] = useState(null)
  const [goToProfile, setGoToProfile] = useState(false)
  const loadUser = useAction(subscriberData => {
    return SubscriberLoaded(subscriberData)
  })
  const history = useHistory()
  const fingerloaded = useAction(finger => {
    localStorage.setItem('fingerid', finger)
    return FingerprintLoaded(finger)
  })
  const goToBegin = useAction(() => goToStep(1))
  const emailRef = useRef()
  const otpRef = useRef()
  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }
  const showSignInPopUp = () => {
    setPopUpState(true)
    setSignedInState(null)
    setGoToProfile(false)
    setOTPPopUpState(false)
    setOTPSent(false)
    setSignInError(null)
  }
  const showProfilePopUp = () => {
    setPopUpState(true)
    setSignedInState(null)
    setGoToProfile(true)
    setOTPPopUpState(false)
    setOTPSent(false)
    setSignInError(null)
  }
  const closeHandler = () => {
    if (signedIn !== true) {
      history.replace('/')
    }
    setSignedInState(null)
    setPopUpState(false)
    setOTPPopUpState(false)
  }
  const signInHandler = () => {
    const email = emailRef.current.value
    if (validateEmail(email)) {
      setSpinnerState(true)
      const form = new FormData()
      form.append('email', email)
      axios.post(
        '/api/sign-in',
        form,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      ).then(response => {
        if (response.data.message === 'sent') {
          setOTPSent(true)
          localStorage.setItem('otpuid', response.data.uid)
          setTimeout(()=>{
            setPopUpState(false)
            setOTPPopUpState(true)
            setSpinnerState(false)
          }, 1000)
          setSignInError(null)
        }
        if (response.data.message === 'subscriber not found') {
          setSignInError('user not found')
          setSpinnerState(false)
          showOTPPopUp(false)
        }
        if (response.data.message === 'incorrect email') {
          setSignInError('incorrect email')
          setSpinnerState(false)
          showOTPPopUp(false)
        }
        // loadUser(response.data)
        // fingerloaded(response.data.uid)
        // goToBegin()
        // setSignedInState(true)
        // setSpinnerState(false)
        // setPopUpState(false)
        // if (goToProfile) {
        //   history.push('/profile')
        // }
        // const menuToggle = document.querySelector('.mobile-menu-toggle.active')
        // menuToggle.click()
      }).catch(err => {
        setSpinnerState(false)
        setSignedInState(false)
        setOTPPopUpState(false)
        setSignInError(null)
        showOTPPopUp(false)
      })
    }
  }

  const OTPHandler = () => {
    const otp = otpRef.current.value
    const uid = localStorage.getItem('otpuid')
    setSpinnerState(true)
    const form = new FormData()
    form.append('uid', uid)
    form.append('otp', otp)
    axios.post(
      '/api/check-otp',
      form,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    ).then(response => {
      if (response.status === 400) {
        if (response.data.message) {
          setOTPError(response.data.message)
          setSpinnerState(false)
        }
      } else {
        fingerloaded(uid)
        loadUser(response.data)
        goToBegin()
        setSignedInState(true)
        setSpinnerState(false)
        setPopUpState(false)
        setOTPPopUpState(false)
        localStorage.removeItem('otpuid')
        if (goToProfile) {
          history.push('/profile')
        }
        const menuToggle = document.querySelector('.mobile-menu-toggle.active')
        menuToggle.click()
      }
    }).catch(err => {
      setSpinnerState(false)
      setSignedInState(false)
      setOTPPopUpState(false)
    })
  }

  const signInPopUp = () => {
    const { t } = useTranslation()
    return (
      <>
        <Suspense fallback={<></>}>
          <LazyPopup
            open={popUpOpen}
            onClose={closeHandler}
            contentStyle={{
              width: 'auto',
              height: 'auto',
              backgroundColor: '#ffd6a0',
              borderRadius: '10px',
              padding: '20px'
            }}
            overlayStyle={{
              backgroundColor: 'rgba(152, 218, 232,0.5)'
            }}
            modal
          >
            <>
              <div className="text-center">
                <div>
                  <p>{t('SignInPopup.Enter the email address associated with your BabyFaceGenerator account to sign in')}:</p>
                  <br/>
                  <input type="email"
                         placeholder={t('SignInPopup.Enter your email address')}
                         className="input-style"
                         ref={emailRef}
                  />
                </div>
                {showSpinner && <>
                  <Spinner size="100px"/>
                </>}
                {signedIn === true && <>
                  <br/>
                  <p className="success-text">You successfully signed in</p>
                </>}
                {signedIn === false && signInError && <>
                  <br/>
                  <p className="error-text">Can't sign in with this email</p>
                </>}
                {otpSent === true && <>
                  <br/>
                  <p className="success-text">We've sent you One-Time Password to email</p>
                </>}
                {signedIn !== true &&
                  <div>
                    <br/>
                    <button className="btn btn-green"
                            onClick={signInHandler}>{t('SignInPopup.Sign In')}
                    </button>
                  </div>
                }
              </div>
            </>
          </LazyPopup>
        </Suspense>
      </>
    )
  }

  const OTPPopUp = () => {
    const [otpCode, setOtpCode] = useState('')
    return (
      <>
        <Suspense fallback={<></>}>
          <LazyPopup
            open={showOTPPopUp}
            onClose={closeHandler}
            contentStyle={{
              width: 'auto',
              height: 'auto',
              backgroundColor: '#ffd6a0',
              borderRadius: '10px',
              padding: '20px'
            }}
            overlayStyle={{
              backgroundColor: 'rgba(152, 218, 232,0.5)'
            }}
            modal
          >
            <>
              <div className="text-center">
                <div>
                  <p>Enter 6-symbol code sent to your email:</p>
                  <br/>
                  <small>One-Time Password will be valid for 20 minutes</small>
                  <br/>
                  <input type="text"
                         placeholder="Enter One-Time Password"
                         className="input-style"
                         ref={otpRef}
                         onChange={e=>{setOtpCode(e.target.value.trim())}}
                  />
                </div>
                {showSpinner && <>
                  <Spinner size="100px"/>
                </>}
                {signedIn === true && <>
                  <br/>
                  <p className="success-text">You successfully signed in</p>
                </>}
                {otpError && <>
                  <br/>
                  <p className="error-text">{otpError}</p>
                </>}
                {signedIn !== true &&
                  <div>
                    <br/>
                    <button className={otpCode.length !== 6 ? 'btn btn-green disabled' : 'btn btn-green'}
                            onClick={OTPHandler}
                            disabled={otpCode.length !== 6}
                    >Submit Code
                    </button>
                  </div>
                }
              </div>
            </>
          </LazyPopup>
        </Suspense>
      </>
    )
  }
  return { signInPopUp, showSignInPopUp, showProfilePopUp, OTPPopUp }
}
